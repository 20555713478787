@font-face {
  font-family: 'Goldplay-LightIt';
  src: url('./Goldplay-LightIt/font.woff2') format('woff2'),
    url('./Goldplay-LightIt/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-Thin';
  src: url('./Goldplay-Thin/font.woff2') format('woff2'),
    url('./Goldplay-Thin/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-Light';
  src: url('./Goldplay-Light/font.woff2') format('woff2'),
    url('./Goldplay-Light/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-Regular';
  src: url('./Goldplay-Regular/font.woff2') format('woff2'),
    url('./Goldplay-Regular/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-ThinIt';
  src: url('./Goldplay-ThinIt/font.woff2') format('woff2'),
    url('./Goldplay-ThinIt/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-BlackIt';
  src: url('./Goldplay-BlackIt/font.woff2') format('woff2'),
    url('./Goldplay-BlackIt/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-Black';
  src: url('./Goldplay-Black/font.woff2') format('woff2'),
    url('./Goldplay-Black/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-SemiBoldIt';
  src: url('./Goldplay-SemiBoldIt/font.woff2') format('woff2'),
    url('./Goldplay-SemiBoldIt/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-BoldIt';
  src: url('./Goldplay-BoldIt/font.woff2') format('woff2'),
    url('./Goldplay-BoldIt/font.woff') format('woff');
}
@font-face {
  font-family: 'Goldplay-Bold';
  src: url('./Goldplay-Bold/font.woff2') format('woff2'),
    url('./Goldplay-Bold/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-Medium';
  src: url('./Goldplay-Medium/font.woff2') format('woff2'),
    url('./Goldplay-Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-MediumIt';
  src: url('./Goldplay-MediumIt/font.woff2') format('woff2'),
    url('./Goldplay-MediumIt/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-RegularIt';
  src: url('./Goldplay-RegularIt/font.woff2') format('woff2'),
    url('./Goldplay-RegularIt/font.woff') format('woff');
}

@font-face {
  font-family: 'Goldplay-SemiBold';
  src: url('./Goldplay-SemiBold/font.woff2') format('woff2'),
    url('./Goldplay-SemiBold/font.woff') format('woff');
}
