body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px;
  padding: 0;
}

/* 
Disable arrows for input of type number
Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

Firefox 
 input[type="number"] {
  -moz-appearance: textfield;
} 
*/
